<script setup lang="ts">
import { Icon } from "@/components/Ui";
import { vOnClickOutside } from "@vueuse/components";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { isMobile } from "@/helpers/breakpoints";
import Amplitude from "@/helpers/amplitude";
import { useUserStore } from "@/store/user";
const userStore = useUserStore();
interface Props {
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  color?: "dark" | "light";
  position?: "header" | "footer";
  onlyIcon?: boolean;
  chevron?: boolean;
  badgeClass?: any;
  badgeWhite?: boolean;
  view?: "row" | "column";
}

type TLocale = {
  code: string;
  iso: string;
  file: string;
  name: string;
  dir: string;
  hash: string;
  type: string;
  flag?: string;
};

const props = defineProps<Props>();
const { locale, locales, setLocale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const showDropdown = ref<boolean>(false);

const allLocales = computed(() => {
  const arrLocales = [...locales.value];
  for (const item of arrLocales) {
    item.flag = computed(() => {
      return getFlagImageName(item);
    });
  }
  return arrLocales;
});

const availableLocales = computed(() => {
  return (allLocales.value as TLocale[])
    .filter((item: TLocale) => item.code !== locale.value)
    .sort((a: TLocale, b: TLocale) => (a.code > b.code ? 1 : -1));
});

const currentLocaleData = computed(() => {
  return (allLocales.value as TLocale[]).find((item) => item.code === locale.value);
});

const getFlagImageName = (itemLocale: TLocale) => {
  const flag = itemLocale.iso.split("-")[1];
  const dic: { [key: string]: { [key: string]: string } } = {
    CN: {
      TW: "tw", // Taiwan
      SG: "sg", // Singapore
    },
    PT: {
      BR: "br", // Brazil
    },
    SA: {
      DZ: "dz", // Algeria
      BH: "bh", // Bahrain
      EG: "eg", // Egypt
      JO: "jo", // Jordan
      IQ: "iq", // Iraq
      YE: "ye", // Yemen
      QA: "qa", // Qatar
      KW: "kw", // Kuwait
      LB: "lb", // Lebanon
      LY: "ly", // Libya
      MA: "ma", // Morocco
      AE: "ae", // UAE
      OM: "om", // Oman
      SA: "sa", // Saudi Arabia
      SY: "sy", // Syria
      SD: "sd", // Sudan
      TN: "tn", // Tunisia
      SO: "so", // Somalia
    },
  };
  return dic?.[flag]?.[userStore.countryCode] ?? flag.toLowerCase();
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
  document.documentElement.classList.toggle("no-scrolled");
};

const changeLanguage = (itemLocale: TLocale) => {
  toggleDropdown();
  Amplitude.Instance.setUserProperties({
    landing_locale: itemLocale.code,
  });
  setLocale(itemLocale.code);
};
</script>

<template>
  <div :class="[$style.root, $style[`disabled-${props.disabled || false}`]]">
    <!--    Badge -->
    <div
      :class="[
        props.badgeClass || lang.badge,
        lang[`badge-position-${props.position}`],
        lang[`badge-row-active-${showDropdown}`],
        lang[`badge-row`],
        lang[`badge-row-${props.size || 'medium'}`],
        props.badgeWhite
          ? lang[`badge-white`]
          : lang[`badge-${props.color || 'dark'}`],
      ]"
      @click="toggleDropdown"
    >
      <template v-if="currentLocaleData">
        <nuxt-img
          :src="`/img/flag/${currentLocaleData.flag}.svg`"
          :class="[lang.flag, lang[`flag-${props.size || 'medium'}`]]"
          :aria-label="currentLocaleData?.iso"
          placeholder
        />
        <span
          v-if="!props.onlyIcon"
          :class="lang[`current-row`]"
        >
          {{ currentLocaleData?.name }}
        </span>
      </template>

      <Icon
        v-if="props?.view !== 'column' && props.chevron"
        name="chevron-down"
        :class="[
          lang.chevron,
          lang[`chevron-active-${showDropdown}`],
          lang[`chevron-${props.size || 'medium'}`],
          lang[`chevron-${props.color || 'dark'}`],
        ]"
      />
    </div>
    <!--    Dropdown-->
    <transition name="fade-slide-up">
      <div
        v-if="!isMobile && showDropdown"
        v-on-click-outside="toggleDropdown"
        :class="[
          lang.dropdown,
          lang[`dropdown-position-${props.position || 'header'}`],
        ]"
      >
        <nuxt-link
          v-for="(locale, index) in availableLocales"
          :key="index"
          :to="switchLocalePath(locale.code)"
          :class="[lang.item, lang[`item-row-${props.size || 'medium'}`]]"
          @click="changeLanguage(locale)"
        >
          <nuxt-img
            :src="`/img/flag/${locale.flag}.svg`"
            :class="[lang.flag, lang[`flag-${props.size || 'medium'}`]]"
          />
          <span>
            {{ locale.name }}
          </span>
        </nuxt-link>
      </div>
    </transition>
    <Teleport to="body">
      <transition name="slide-up">
        <div
          v-if="isMobile && showDropdown"
          ref="dropdownEl"
          v-on-click-outside="toggleDropdown"
          :class="[lang.dropdown]"
        >
          <div
            class="overflow-y-auto h-full flex flex-col gap-3 py-6 px-2 absolute w-full"
          >
            <span
              :class="lang.close"
              @click="toggleDropdown"
            >
              <Icon name="close-outline" />
            </span>
            <nuxt-link
              v-for="(locale, index) in availableLocales"
              :key="index"
              :to="switchLocalePath(locale.code)"
              :class="[lang.item, lang[`item-row-${props.size || 'medium'}`]]"
              @click="changeLanguage(locale)"
            >
              <nuxt-img
                :src="`/img/flag/${locale.flag}.svg`"
                :class="[lang.flag, lang[`flag-${props.size || 'medium'}`]]"
              />
              <span>
                {{ locale.name }}
              </span>
            </nuxt-link>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<style lang="scss" module>
.root {
  @apply relative z-[1200] w-full h-full md:w-fit md:h-fit;
}

.disabled {
  &-true {
    @apply opacity-50 pointer-events-none;
  }

  &-false {
    @apply opacity-100 pointer-events-auto;
  }
}
</style>
<style lang="scss" module="lang">
.badge {
  @apply cursor-pointer relative text-white w-full rounded-[12px] transition-all;
  @apply flex items-center justify-center;

  &-position-header {
    @apply h-10 xs:h-12 w-10 xs:w-12 flex items-center justify-center rounded-12;
    @screen md {
      @apply w-fit h-full md:h-fit;
    }
  }
  &-position-footer {
    @apply w-fit h-full md:h-fit;
  }

  &-row {
    @apply flex-row;
    @apply border-[1.25px] border-solid;

    // Size
    &-small {
      @apply py-[5px] px-[10px];
      @apply gap-[6px];
      @apply font-normal text-[16px];
    }

    &-medium {
      @apply py-2 px-3;
      @apply gap-2;
      @apply font-normal text-[16px];
    }

    &-large {
      @apply py-[12px] px-4;
      @apply gap-3;
      @apply font-medium text-[15px];
    }

    &-active-true {
      @apply pointer-events-none;
      @apply ring-[1.25px];
    }

    &-active-false {
      @apply pointer-events-auto;
    }
  }

  // Color

  &-dark {
    @apply text-neutral-800 border-neutral-300;
    @apply hover:border-neutral-900 ring-neutral-900;
    @apply dark:text-white dark:border-neutral-800;
    @apply dark:hover:border-white dark:ring-white;
  }

  &-light {
    @apply text-white border-neutral-800;
    @apply hover:border-white ring-white;
  }

  &-white {
    @apply text-neutral-800 border-white/30;
    @apply hover:border-white ring-white;
  }
}

.current {
  &-row {
    @apply mr-auto;
  }
}

.flag {
  @apply rounded-full;

  // Size
  &-small {
    @apply w-6 h-6 min-w-[24px] min-h-[24px];
  }

  &-medium {
    @apply w-[28px] h-[28px] min-w-[28px] min-h-[28px];
  }

  &-large {
    @apply w-[30px] h-[30px] min-w-[30px] min-h-[30px];
  }
}

.chevron {
  @apply transform transition-all;

  &-active-true {
    @apply rotate-180;
  }

  &-active-false {
    @apply rotate-0;
  }

  // Color

  &-dark {
    @apply text-neutral-800 dark:text-neutral-100;
  }

  &-light {
    @apply text-neutral-100;
  }

  // Size
  &-small {
    @apply w-4 h-4 min-w-[16px];
  }

  &-medium {
    @apply w-5 h-5 min-w-[20px];
  }

  &-large {
    @apply w-6 h-6 min-w-[24px];
  }
}

.dropdown {
  @apply fixed m-0 top-0 left-0 right-0 bottom-0 min-h-[100svh] h-full mb-[58px];
  @apply overflow-y-auto z-[3000] grid grid-cols-1 gap-2 w-full min-w-max;
  @apply w-full border-solid border-neutral-800;
  @apply bg-neutral-900/90 backdrop-blur-md;

  @screen md {
    @apply z-[2000] grid-cols-4 left-0 right-auto bottom-0 rounded-[12px] border min-h-fit h-auto;
    @apply p-2;
  }

  &-position-header {
    @screen md {
      @apply fixed ltr:left-0 ltr:right-0 rtl:left-0  rtl:right-0 mx-auto w-fit bottom-auto top-auto mt-2;
    }

    @screen lg {
      @apply absolute ltr:right-0 ltr:left-auto rtl:right-auto rtl:left-0;
    }
  }
  &-position-footer {
    @screen md {
      @apply absolute ltr:left-0 ltr:right-auto rtl:left-auto rtl:right-0;
      @apply top-auto bottom-0 mb-[4.25rem];
    }
  }
}

.item {
  @apply w-fit md:w-full flex items-center justify-start ltr:mr-auto rtl:ml-auto rounded-[10px];
  @apply text-white transition-all cursor-pointer;
  @apply hover:bg-neutral-700;

  &-row {
    &-small {
      @apply p-2;
      @apply gap-[6px];
      @apply font-normal text-[16px];
    }

    &-medium {
      @apply py-2 pl-3 pr-5;
      @apply gap-2;
      @apply font-normal text-[16px];
    }

    &-large {
      @apply p-2;
      @apply gap-2;
      @apply font-medium text-[15px];
    }
  }
}

.close {
  @apply flex items-center justify-center h-12 w-12 rounded-full bg-white bg-opacity-20 text-white;
  @apply transition-all focus:bg-black focus:bg-opacity-30 focus:outline-none;
  @apply hover:scale-[0.9];
  @apply fixed top-0 ltr:right-0 rtl:left-0 m-5;
  @apply cursor-pointer z-[999];
  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}
</style>

