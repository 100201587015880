<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import "moment-timezone";

const currentTime = ref("");
const numBg = ref<number>(1);
const isHover = ref<boolean>(false);
const img = useImage();
const backgroundStyle = computed<string>(() => {
  const imgUrl = img(`/img/clock/${numBg.value}.png`, { width: 220, format: "webp" });
  return { backgroundImage: `url('${imgUrl}')` };
});

const onLeave = () => {
  isHover.value = false;
  setTimeout(() => {
    if (numBg.value === 3) {
      numBg.value = 1;
    } else {
      numBg.value++;
    }
  }, 300);
};
const updateTime = () => {
  const time = moment().tz("America/Los_Angeles");
  currentTime.value = {
    h: time.format("HH"),
    m: time.format("mm"),
    s: time.format("ss"),
    weekDay: time.format("ddd"),
    date: time.format("MMM DD")
  };
};
onMounted(() => {

  updateTime();
  setInterval(updateTime, 1000);
});
</script>

<template>
  <div :class="clock.root" @mouseover="isHover = true" @mouseleave="onLeave">
    <div :class="clock.btn">
      <transition name="slide-up" appear>
        <div v-show="isHover" :class="clock.bg" :style="backgroundStyle" />
      </transition>
      <div :class="[clock.wrapper]">
        <div :class="clock.col">
          <span>PST</span>
          <span>Time</span>
        </div>
        <div :class="clock.time">
          <div :class="clock.value" class="rtl:order-3">
            <span>88</span>
            <span>{{ currentTime.h }}</span>
          </div>
          <div :class="clock.value" class="rtl:order-2">
            <span>88</span>
            <span>{{ currentTime.m }}</span>
          </div>
          <div :class="clock.value" class="rtl:order-1">
            <span>88</span>
            <span>{{ currentTime.s }}</span>
          </div>
        </div>
        <div :class="clock.col">
          <span>{{ currentTime.weekDay }}</span>
          <span>{{ currentTime.date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module="clock">
.root {
  @apply p-[3px] h-fit relative;
  border-radius: 13px;
  border: 1px solid var(--neutral-n-800, #313438);
}

.btn {
  @apply px-[10px] py-1 rounded-[10px] bg-[#80807E] relative overflow-hidden;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.wrapper {
  @apply flex justify-center items-center gap-3 w-full z-10;
}

.col {
  @apply flex flex-col items-center text-center text-[10px] leading-none uppercase font-sf-mono text-black;
}

.time {
  @apply font-segment grid grid-cols-3 place-items-center  gap-2 text-[24px] translate-y-[2px];
}

.value {
  @apply relative leading-none w-fit flex;

  & span {
    &:first-of-type {
      @apply absolute opacity-70 text-[#686866] z-0;
    }

    &:last-of-type {
      @apply text-black z-10;
    }
  }
}

.bg {
  @apply w-full h-full left-0 top-0 right-0 bottom-0 absolute bg-no-repeat bg-cover bg-center z-20;
}
</style>