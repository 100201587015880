<script setup lang="ts">
import { Modal } from "@/components/Ui";
import { AuthQuickWithPromo } from "@/components/Auth";
import { useSessionStorage } from "@vueuse/core";
import { onMounted, onUnmounted, ref, watch } from "vue";

const isClosedAuth = useSessionStorage("is-closed-auth", false);
const showAuth = ref<boolean>(false);
const direction = ref<string | null>(null);
const toggleAuthBanner = () => {
  if (!isClosedAuth.value) {
    setTimeout(() => {
      showAuth.value = true;
    }, 1000);
  }
  return;
};
const closeAuthBanner = () => {
  showAuth.value = false;
  isClosedAuth.value = true;
};
onMounted(() => {
  // Detect mouse page leave
  document.addEventListener("mouseleave", () => {
    toggleAuthBanner();
  });

  // Detect scroll up
  let oldScrollY = window.scrollY;
  window.onscroll = function() {
    if (oldScrollY < window.scrollY) {
      direction.value = "down";
    } else {
      direction.value = "up";
    }
    oldScrollY = window.scrollY;

    // Detect bottom page
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      toggleAuthBanner();
    }
  };
});
watch(
  () => direction.value,
  (newVal) => {
    if (newVal === "up") {
      toggleAuthBanner();
    }
  });
onUnmounted(() => {
  showAuth.value = false;
  direction.value = null;
  document.removeEventListener("mouseleave", () => {
  });
});
</script>
<template>
  <Modal
    v-if="showAuth"
    :title="$t('auth.quickWithPromoTitle')"
    position="top-right"
    closable
    island
    :modal-class="$style.root"
    @close="closeAuthBanner"
  >
    <AuthQuickWithPromo color="light-grey" />
  </Modal>
</template>

<style lang="scss" module>
.root {
  @apply w-full max-w-[400px] mt-28;
}
</style>
