export const blockedCountriesCodes: { [key: string]: boolean } = {
  AF: true,
  MM: true,
  BI: true,
  BY: true,
  CF: true,
  CD: true,
  CU: true,
  HK: true,
  IR: true,
  IQ: true,
  CI: true,
  KP: true,
  LB: true,
  LR: true,
  LY: true,
  NI: true,
  RU: true,
  SO: true,
  ZW: true,
  SD: true,
  SS: true,
  SY: true,
  UA: true,
  US: true,
  VE: true,
  YE: true,
  XK: true
};
export const blockedFullCountriesCodes: { [key: string]: boolean } = {
  //PST-T-3382 Full blocking of the user from the list of countries
  //RU: true,
};
