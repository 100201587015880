<script setup lang="ts">
import { Modal, Button } from "@/components/Ui";
import { blockedCountriesCodes, blockedFullCountriesCodes } from "@/config/countries";
import { onSocial } from "@/helpers/events";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/user";
import Amplitude from "~/helpers/amplitude";

const route = useRoute();
const { t } = useI18n();
const isOpen = useSessionStorage("unavailable-countries-modal", false);
const countryCode = ref<string>("");
const isShowCountryList = ref<boolean>(false);
const userStore = useUserStore();

watch(() => userStore.countryCode, () => {
  if (route.path.endsWith("/pst")) return;
  if (!userStore.countryCode) return;
  if (!blockedCountriesCodes[userStore.countryCode]) return;
  countryCode.value = userStore.countryCode;
  isOpen.value = true;
  if (blockedFullCountriesCodes[countryCode.value]) {
    Amplitude.Instance.logEvent(
      "SHOW_FULL_GEOBLOCK_MODAL_LANDING",
      {}
    );
  }
});

const isBlockedFullCountryCode = computed(() => {
    if (!countryCode.value) return false;
    return blockedFullCountriesCodes[countryCode.value];
});

</script>
<template>
  <Modal
    v-if="isOpen"
    :fullscreen="true"
    position="center-center"
    modal-class="w-full !bg-black !bg-opacity-40 overflow-hidden overflow-y-auto"
  >

    <!-- Mobile version -->
    <div class="sm:hidden bg-modal-mobile flex flex-col justify-end p-2 h-full">
      <div class="flex flex-none bg-white p-4 flex-col rounded-6">
        <div class="flex flex-none text-[#15181E] text-center text-5 font-medium leading-6 mb-4">
          {{
            t("unavailable-country.title", {
              c: t(`unavailable-country-list.${countryCode}`),
            })
          }}
        </div>
        <div class="flex flex-none flex-col">
          <div class="flex flex-none mb-2">
            <Button
              color="grey"
              size="small"
              class="w-full"
              @click="isShowCountryList = true"
            >
              {{ t("unavailable-country.button.list") }}
            </Button>
          </div>
          <div class="flex flex-none" v-if="!isBlockedFullCountryCode">
            <Button
              size="small"
              class="w-full"
              @click="isOpen = !isOpen"
            >
              {{ t("unavailable-country.button.submit") }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop Support btn -->
    <div class="ml-auto mb-8 md:mb-0 fixed sm:flex hidden right-4 top-5 bg-[#E3F3FE] rounded-full w-11 h-11">
      <Button
        color="custom"
        size="custom"
        icon="headphone"
        icon-class="w-6 h-6"
        class="w-8 h-8 text-[#194BFB] m-auto"
        @click="onSocial('', 'telegram', true, $i18n.locale)"
      />
    </div>

    <!-- Desktop version -->
      <div
      class="flex-col items-center text-center justify-center max-w-[27.5rem] m-auto sm:flex hidden bg-white rounded-[6px] p-10"
    >
        <div class="flex flex-col gap-10">
          <div class="flex flex-none">
          <nuxt-img
            src="/img/geoblock-map.png"
            format="webp"
            class="w-full"
          />
          </div>
          <div
            class="text-[#15181E] text-[1.5rem] leading-[1.75rem] font-bold md:px-14 flex flex-none"
          >
            {{
              t("unavailable-country.title", {
                c: t(`unavailable-country-list.${countryCode}`),
              })
            }}
          </div>
          <div class="flex flex-none text-[#15181E] text-[1rem] leading-5" v-if="!isBlockedFullCountryCode">
            {{
              t("unavailable-country.text", {
                c: t(`unavailable-country-list.${countryCode}`),
              })
            }}
          </div>
          <div v-else class="flex flex-none text-[#15181E] text-[1rem] leading-5">
            {{
              t("unavailable-country.text-geoblock")
            }}
          </div>
          <div class="flex flex-none flex-col">
            <div class="mb-3" v-if="!isBlockedFullCountryCode">
              <Button
                size="small"
                class="w-full"
                @click="isOpen = !isOpen"
              >
                {{ t("unavailable-country.button.submit") }}
              </Button>
            </div>
            <div>
              <Button
                color="grey"
                size="small"
                class="w-full text-[#6E7687] font-medium text-base"
                @click="isShowCountryList = true"
              >
                {{ t("unavailable-country.button.list") }}
              </Button>
            </div>
          </div>
        </div>

    </div>

  </Modal>
  <Modal
    v-if="isShowCountryList"
    position="center-center"
    closable
    :title="t('unavailable-country.button.list')"
    class="!z-[3000]"
    modal-class="w-full overflow-y-auto md:max-w-[37.5rem]"
    @close="isShowCountryList = false"
  >
    <ul class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-2">
      <li
        v-for="(value, code) in blockedCountriesCodes"
        :key="`${code}_${value}`"
        class="text-base"
      >
        {{ t(`unavailable-country-list.${code}`) }}
      </li>
    </ul>
  </Modal>
</template>
<style scoped>
.bg-modal-mobile {
  background: rgba(21, 25, 29, 0.4);
}
</style>